import React from "react"
import {useLocation} from "react-router-dom"
import {Bars3Icon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {UnstyledLink} from "../../Link.tsx"
import {MenuButton} from "./MenuButton.tsx"
import {useNavigationItems} from "./useNavigationItems.ts"

export const SlideOutMenu: React.FC = () => {
  const {pathname} = useLocation()
  const [isOpen, setIsOpen] = React.useState(false)

  const navigationItems = useNavigationItems()

  React.useEffect(() => {
    setIsOpen(false)
  }, [pathname])

  return (
    <div className={"flex select-none items-center font-semibold md:hidden"}>
      <MenuButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <Bars3Icon className={"size-full"} />
      </MenuButton>
      <div className={"relative isolate z-40"}>
        {isOpen && <div className={"fixed inset-0 z-40"} onClick={() => setIsOpen(false)} />}
        <div
          className={twMerge([
            "fixed bottom-0 left-0 top-[var(--header-height)] w-[300px] -translate-x-full transition-all max-sm:w-full",
            "cr-shadow z-50 flex flex-col bg-cr-white py-3",
            isOpen && "translate-x-0",
          ])}
        >
          {navigationItems.map(item => {
            const isSelected = pathname.startsWith(item.href)

            return (
              <UnstyledLink
                key={item.href}
                to={item.href}
                className={twMerge("px-8 py-3 text-cr-grey-50", isSelected ? "text-cr-blue" : "hover:text-cr-grey-90")}
                {...("isExternal" in item && item.isExternal ? {target: "_blank"} : {})}
              >
                {item.name}
              </UnstyledLink>
            )
          })}
        </div>
      </div>
    </div>
  )
}
