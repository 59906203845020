import React from "react"
import {toast} from "react-toastify"
import {AxiosError, isAxiosError} from "axios"

import {i18n} from "../i18n"
import {getApiValidationMessages, isAxiosValidationError} from "../utils/validation.ts"
import {rollbar} from "./index"

/* eslint-disable no-console */

const requestError = (error: unknown) => {
  if (isAxiosError(error)) {
    console.error(`Axios error ${error.code}!`, error)

    let message: React.ReactNode = error.message

    if (isAxiosValidationError(error)) {
      message = (
        <ul>
          {getApiValidationMessages(error.response.data, "errors").map((err, index) => (
            <li key={index}>{err}</li>
          ))}
        </ul>
      )
    }

    toast.error(message)
    rollbar.error(
      new Error(error.message), // This is to get the tracing info from here instead of from somewhere inside Axios
      cleanAxiosError(error)
    )
  } else {
    console.error(error)
    let message
    try {
      message = String(error)
    } catch (e) {
      console.error("Error while parsing error message", e)
      message = i18n.t("T_Unexpected error occurred while performing a request.")
    }
    toast.error(message)
    rollbar.error("Caught miscellaneous error", error as Error, error instanceof Object ? {...error} : undefined)
  }
}

/**
 * Returns the important data from an Axios error
 */
const cleanAxiosError = (error: AxiosError) => {
  const {code, message, name, response} = error

  let requestDataJSON = response?.config.data
  try {
    // Convert the request body from string to JSON if possible
    // Rollbar will scrub sensitive fields from JSON, but not from a string
    requestDataJSON = JSON.parse(requestDataJSON)
  } catch {
    // If not possible to convert, do nothing
  }

  return {
    code,
    message,
    name,
    request: {
      baseURL: response?.config.baseURL,
      url: response?.config.url,
      params: response?.config.params,
      data: requestDataJSON,
      method: response?.config.method,
      headers: response?.config.headers,
    },
    response: {
      data: response?.data,
      status: response?.status,
      statusText: response?.statusText,
      headers: response?.headers,
    },
  }
}

export default requestError
